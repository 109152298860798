/**
 * Angular imports.
 */
import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Used to validate the no emoji validation.
 */
export const OnlyAlphaNumericValidator =
  (control: (AbstractControl)): ValidationErrors | null => {

    const value: (string) = control.value || '';

    if (!value) {
      return null;
    }

    const alphaNumeric = /^[a-z\d\s]+$/i;
    if (alphaNumeric.test(value) === false) {
      return {
        alphaNumeric: true
      };
    }
    return null;
  };
